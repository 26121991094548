"use client";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";
import { LoginLink } from "@kinde-oss/kinde-auth-nextjs";
import { ChevronDown, ChevronUp, Filter } from "lucide-react";
import { VisaSlot, TCNOption } from "@/app/lib/types";
import { cn } from "@/lib/utils";

type EarliestSlotDatesDashboardProps = {
  slotDates: VisaSlot[];
};

const columns = [
  { label: "Country", sortBy: "country" },
  { label: "City", sortBy: "city" },
  { label: "Visa Type", sortBy: "visa_type" },
  { label: "Earliest Date", sortBy: "date" },
  { label: "Fetched", sortBy: "fetched_at" },
  { label: "TCN", sortBy: "tcn" },
];

function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

const CollapsibleFilter = ({
  isExpanded,
  onToggle,
  children,
}: {
  isExpanded: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div className="mb-6">
      <button
        className="w-full flex items-center justify-between text-left font-semibold py-3 px-4 bg-blue-50 text-blue-700 rounded-lg focus:outline-none hover:bg-blue-100 transition-colors duration-200"
        onClick={onToggle}
      >
        <span className="flex items-center">
          <Filter size={18} className="mr-2" />
          Filter Options
        </span>
        {isExpanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
      </button>
      {isExpanded && (
        <div className="mt-4 p-4 bg-white rounded-lg shadow-md">{children}</div>
      )}
    </div>
  );
};

export default function EarliestSlotDatesDashboard({
  slotDates,
}: EarliestSlotDatesDashboardProps) {
  const searchParams = useSearchParams();
  const countryList = searchParams?.get("countries")?.split(",") || [];
  const visaType = searchParams?.get("visaType") || "H1B";
  const tcn = searchParams?.get("tcn") || (visaType === "H1B" ? "yes" : "no");

  const countryFilters = Array.from(
    new Set(slotDates.map((slot) => slot.country))
  ).sort();
  const visaTypeFilters = Array.from(
    new Set(slotDates.map((slot) => slot.type))
  ).sort();
  const allTCNOptions: TCNOption[] = ["yes", "no"];
  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    countryList || ["Canada"]
  );
  const [selectedTCN, setSelectedTCN] = useState<string>(tcn);
  const [sortBy, setSortBy] = useState("fetched_at");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isFilterExpanded, setIsFilterExpanded] = useState(!isMobile);

  const [selectedVisaType, setSelectedVisaType] = useState(visaType);

  useEffect(() => {
    setIsFilterExpanded(!isMobile);
  }, [isMobile]);

  const filteredSlotDates = slotDates
    .filter(
      (slot) =>
        selectedCountries.length === 0 ||
        selectedCountries.includes(slot.country)
    )
    .filter((slot) => tcn === slot.tcn)
    .filter((slot) => slot.type === selectedVisaType);

  const filteredAndSortedSlotDates = filteredSlotDates.sort((a, b) => {
    const order = sortOrder === "asc" ? 1 : -1;
    switch (sortBy) {
      case "country":
        return order * a.country.localeCompare(b.country);
      case "city":
        return order * a.city.localeCompare(b.city);
      case "date":
        return (
          order * (new Date(a.date).getTime() - new Date(b.date).getTime())
        );
      case "fetched_at":
        return (
          order *
          (new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
        );
      case "tcn":
        return order * a.tcn.localeCompare(b.tcn);
      default:
        return 0;
    }
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set("countries", selectedCountries.join(","));
    url.searchParams.set("visaType", selectedVisaType);
    url.searchParams.set("tcn", selectedTCN);
    if (selectedCountries.length === 0) url.searchParams.delete("countries");
    window.history.pushState({}, "", url.toString());
  }, [selectedCountries, selectedTCN, selectedVisaType]);

  const handleSort = (columnSortBy: string) => {
    if (sortBy === columnSortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnSortBy);
      setSortOrder("asc");
    }
  };

  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-100 shadow-lg rounded-lg p-4 md:p-8 text-gray-800">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-center text-indigo-800">
        US Visa Interview Slot Availability
      </h1>

      <CollapsibleFilter
        isExpanded={true}
        // isExpanded={isFilterExpanded}
        onToggle={() => setIsFilterExpanded(!isFilterExpanded)}
      >
        <div className="space-y-6">
          <div>
            <h2 className="font-semibold mb-3 text-lg">Select Countries</h2>
            <div className="flex flex-wrap gap-2">
              {countryFilters.map((country) => (
                <button
                  key={country}
                  className={cn(
                    "px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200",
                    selectedCountries.includes(country)
                      ? "bg-indigo-600 text-white hover:bg-indigo-700"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  )}
                  onClick={() =>
                    setSelectedCountries((prev) =>
                      prev.includes(country)
                        ? prev.filter((c) => c !== country)
                        : [...prev, country]
                    )
                  }
                >
                  {country}
                </button>
              ))}
            </div>
          </div>

          <div>
            <h2 className="font-semibold mb-3 text-lg">Select Visa Type</h2>
            <div className="flex flex-wrap gap-2">
              {visaTypeFilters.map((visaType) => (
                <button
                  key={visaType}
                  className={cn(
                    "px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200",
                    selectedVisaType === visaType
                      ? "bg-indigo-600 text-white hover:bg-indigo-700"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  )}
                  onClick={() => {
                    // set TCN as no for B visa types
                    if (visaType !== "H1B") setSelectedTCN("no");
                    if (visaType === "H1B") setSelectedTCN("yes");
                    setSelectedVisaType(visaType);
                  }}
                >
                  {visaType}
                </button>
              ))}
            </div>
          </div>

          <div>
            <h2 className="font-semibold mb-3 text-lg">
              TCN(Third Country National) Options
            </h2>
            <div className="flex flex-wrap gap-2">
              {allTCNOptions.map((tcnOption) => (
                <button
                  key={tcnOption}
                  className={cn(
                    "px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200",
                    tcnOption === selectedTCN
                      ? "bg-indigo-600 text-white hover:bg-indigo-700"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  )}
                  onClick={() => setSelectedTCN(tcnOption)}
                >
                  {tcnOption === "yes" ? "TCN" : "Non-TCN"}
                </button>
              ))}
            </div>
          </div>
        </div>
      </CollapsibleFilter>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Set Alert
              </th>
              {columns.map((column) => (
                <th
                  key={column.label}
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 transition-colors duration-200"
                  onClick={() => handleSort(column.sortBy)}
                >
                  <div className="flex items-center space-x-1">
                    <span>{column.label}</span>
                    {sortBy === column.sortBy && (
                      <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredAndSortedSlotDates.map((entry, index) => (
              <tr
                key={`${entry.country}-${entry.city}-${new Date(
                  entry.created_at
                ).getTime()}`}
                className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
              >
                <td className="px-4 py-3 whitespace-nowrap text-sm">
                  <LoginLink
                    postLoginRedirectURL="/app"
                    className="bg-indigo-600 hover:bg-indigo-700 text-white font-medium py-1 px-3 rounded-full text-xs transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    Set Alert
                  </LoginLink>
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm">
                  {entry.country}
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm">
                  {entry.city}
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm">
                  {entry.type}
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm">
                  {new Date(entry.date).toLocaleDateString()}
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm">
                  {(() => {
                    const timeDiff =
                      Date.now() - new Date(entry.created_at).getTime();
                    const secondsDiff = Math.floor(timeDiff / 1000);
                    const minutesDiff = Math.floor(secondsDiff / 60);
                    const hoursDiff = Math.floor(minutesDiff / 60);

                    if (secondsDiff < 60) {
                      return `${secondsDiff} seconds ago`;
                    } else if (minutesDiff < 60) {
                      return `${minutesDiff} minutes ago`;
                    } else {
                      return `${hoursDiff} hours ago`;
                    }
                  })()}
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm">
                  {entry.tcn === "yes" ? "Yes" : "No"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
